import {useLogAppUse} from '@/hooks/useLogAppUse';
import {SentryRoutes} from '@/main';
import {MarketplacePage} from '@/pages/ENT/Marketplace/MarketplacePage/MarketplacePage';
import React, {useEffect} from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import {Route} from 'react-router-dom';

const UpdatesPage = lazyWithPreload(() => import('../../pages/ENT/Updates'));

const CommunitiesPage = lazyWithPreload(
  () => import('../../pages/ENT/Communities'),
);

const SideBar = lazyWithPreload(() => import('../../layout/SideBar'));

const ContactPage = lazyWithPreload(
  () => import('../../pages/ENT/Contacts/ContactDetailsPage'),
);
const Contacts = lazyWithPreload(() =>
  import('../../pages/ENT/Contacts/ContactsPage').then(module => ({
    default: module.ContactsPage,
  })),
);
const Packages = lazyWithPreload(
  () => import('../../pages/ENT/Packages/PackagesPage'),
);
const Keys = lazyWithPreload(() =>
  import('../../pages/ENT/Keys/KeysPage').then(module => ({
    default: module.KeysPage,
  })),
);
const CommunityProductsPage = lazyWithPreload(
  () => import('../../pages/ENT/CommunityProducts'),
);
const HomePage = lazyWithPreload(
  () => import('../../pages/ENT/Homes/HomeDetailsPage'),
);
const Homes = lazyWithPreload(() =>
  import('../../pages/ENT/Homes/HomesListPage').then(module => ({
    default: module.HomesPage,
  })),
);
const Profile = lazyWithPreload(() => import('../../pages/ENT/ProfilePage'));
const PageNotFound = lazyWithPreload(() => import('../../pages/ENT/404Page'));
const Layout = lazyWithPreload(() => import('../../layout'));
const Events = lazyWithPreload(() => import('../../pages/ENT/Events'));
const Amenities = lazyWithPreload(
  () => import('../../pages/ENT/Amenities/AmenitiesListPage'),
);
const ConversationsPage = lazyWithPreload(
  () => import('../../pages/ENT/Conversations/Conversations'),
);
const VisitorsPage = lazyWithPreload(() =>
  import('../../pages/ENT/Visitors/VisitorsPage').then(module => ({
    default: module.VisitorsPage,
  })),
);
const HomesPackagesPageSwitch = lazyWithPreload(() =>
  import(
    '../../pages/ENT/Packages/HomePackagesPage/HomePackagesPageSwitch'
  ).then(module => ({
    default: module.HomePackagesPageSwitch,
  })),
);

const componentsToPreload = [
  UpdatesPage,
  CommunitiesPage,
  SideBar,
  ContactPage,
  Contacts,
  Packages,
  Keys,
  CommunityProductsPage,
  HomePage,
  Homes,
  Profile,
  PageNotFound,
  Layout,
  Events,
  Amenities,
  ConversationsPage,
  VisitorsPage,
  HomesPackagesPageSwitch,
];

const ConciergeRoot: React.FC = () => {
  useLogAppUse();

  useEffect(() => {
    componentsToPreload.forEach(component => component.preload());
  }, []);

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<Packages />} />
        <Route path="/events/:id?/:action?" element={<Events />} />
        <Route
          path="/updates/:id?/:action?/:communityId?"
          element={<UpdatesPage />}
        />
        <Route
          path="/contact-profiles/:contactId?/:conversationId?"
          element={<ContactPage />}
        />
        <Route path="/contact-profiles/" element={<Contacts />} />
        <Route path="/packages/:packageId?" element={<Packages />} />
        <Route
          path="/keys/:keyId?/:action?/:keyholderId?/:action?"
          element={<Keys />}
        />
        <Route path="/keys" element={<Keys />} />
        <Route
          path="/community-products/:id?"
          element={<CommunityProductsPage />}
        />
        <Route
          path="/homes/:homeId?/packages/:packageId?"
          element={<HomesPackagesPageSwitch />}
        />
        <Route path="/homes/:homeId" element={<HomePage />} />
        <Route path="/homes" element={<Homes />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/facilities/:id?/:action?" element={<Amenities />} />
        <Route
          path="/messages/:conversationId?"
          element={<ConversationsPage />}
        />
        <Route
          path="/visitors/qr-codes/:communityId"
          element={<VisitorsPage />}
        />
        <Route path="/visitors/qr-codes" element={<VisitorsPage />} />
        <Route path="/visitors/:id?" element={<VisitorsPage />} />
        <Route path="/marketplace/:id?" element={<MarketplacePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </SentryRoutes>
  );
};

export default ConciergeRoot;
