export async function convertFileUrlToFile(
  fileUrl: string,
): Promise<File | null> {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const fileName = extractFileNameFromUrl(fileUrl);
    const extension = getFileExtension(fileName);
    const mimeType = getMimeTypeFromExtension(extension);
    const file = new File([blob], fileName, {type: mimeType});
    return file;
  } catch (err) {
    return null;
  }
}

function getFileExtension(filename: string): string {
  const dotIndex = filename.lastIndexOf('.');
  return filename.substring(dotIndex);
}

function getMimeTypeFromExtension(extension: string): string | undefined {
  const mimeTypeMap: {[extension: string]: string} = {
    '.pdf': 'application/pdf',
    '.doc': 'application/msword',
    '.docx':
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx':
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.pptx':
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.png': 'image/png',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.gif': 'image/gif',
    '.txt': 'text/plain',
  };

  return mimeTypeMap[extension.toLowerCase()];
}

function extractFileNameFromUrl(url: string): string {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}

export function fullImageUrl<T extends string | undefined | null>(url: T): T {
  if (url) {
    return `${import.meta.env.VITE_IMAGE_PREFIX || ''}${url}` as T;
  }
  return url;
}

export const isUrlPdf = (url: string | undefined) => {
  return url && url.split('.').pop()?.toLowerCase().split('?')[0] === 'pdf';
};

export const isVideo = (extension: string) => {
  return ['mp4', 'mov'].includes(extension?.toLowerCase());
};

export const isDisplayableInIframe = (extension: string) => {
  return ['pdf', 'txt', 'html', 'htm'].includes(extension?.toLowerCase());
};
