import {Backdrop, Box, IconButton, Button, Typography} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DownloadIcon from '@mui/icons-material/Download';
import {useIsMobile} from '@/hooks/useIsMobile';
import {isImage} from '@/utils/images';
import {isVideo, isDisplayableInIframe} from '@/utils/files';

type FileBackdropProps = {
  file: File | string;
  open: boolean;
  onClose: () => void;
};

export const FileBackdrop = ({file, open, onClose}: FileBackdropProps) => {
  const isMobile = useIsMobile('md');
  const extension =
    file instanceof File
      ? file.name.split('.').pop()?.toLowerCase()
      : file.split('?')[0]?.split('.').pop()?.toLowerCase();
  const fileUrl = file instanceof File ? URL.createObjectURL(file) : file;
  const fileName =
    file instanceof File
      ? file.name
      : fileUrl.split('/').pop()?.split('?')[0] || 'file';

  const renderContent = () => {
    if (isImage(extension || '')) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={fileUrl}
            alt={file instanceof File ? file.name : ''}
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
              borderRadius: 8,
            }}
          />
        </Box>
      );
    }

    if (isVideo(extension || '')) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <video
            src={fileUrl}
            controls
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              borderRadius: 8,
            }}
          />
        </Box>
      );
    }

    if (isDisplayableInIframe(extension || '')) {
      return (
        <iframe
          src={window.chrome ? `${fileUrl}#toolbar=0` : fileUrl}
          style={{
            width: isMobile ? '100%' : '60%',
            height: '100%',
            border: 'none',
            borderRadius: 4,
          }}
          title="file-preview"
        />
      );
    }

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.3)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '500px',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            This file type cannot be previewed
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{mb: 3}}>
            The file "{fileName}" cannot be displayed in the browser. You can
            download it instead.
          </Typography>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            href={fileUrl}
            download={fileName}
            onClick={e => e.stopPropagation()}
          >
            Download File
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Backdrop
      onClick={onClose}
      open={open}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: 'white',
          padding: 0.5,
          borderRadius: 1,
        }}
      >
        <CloseRoundedIcon />
      </IconButton>
      {renderContent()}
    </Backdrop>
  );
};
